import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import stores from '../../stores'

export const UNAUTHORIZED_CODE = 401
export const FORBIDDEN_CODE = 403
export const NOT_IMPLEMENTED = 501

export const BASE_API_URL = process.env.REACT_APP_API_URL as string

const instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 10000,
})

instance.interceptors.request.use(
  (config) => {
    const { accessToken } = stores.loginStore

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      }
    }

    return config
  },
  (error) => error
)

instance.interceptors.response.use(
  (response) => response,
  async (err: AxiosError) => {
    const { loginStore } = stores

    if (err.response) {
      const { status, data } = err.response

      if ([FORBIDDEN_CODE].includes(status)) {
        if (data?.message === 'token incorrect') {
          return loginStore.clear()
        }
        return loginStore.refreshTokens().then(() => {
          return instance(err.config)
        })
      }
    }

    if (loginStore.operator && err.response) {
      if (err.response.status === UNAUTHORIZED_CODE) {
        try {
          await loginStore.logout()
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }

    if (err.response?.status) toast.error(`Error status: ${err.response?.status}`)

    return Promise.reject(err)
  }
)

export default instance
