import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { endOfDay, format, startOfMonth } from 'date-fns'
import { DateRange } from 'react-day-picker'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_RowVirtualizer,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Box, Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Currency, DateRangePicker } from '../index'
import { IPlayer, IPlayerFinance } from '../../api/players'
import { ApiPlayers } from '../../api'
import { dateFallback, EDateFormat, formatToMoscowTZ } from '../../utils/date'
import { moneyFormat } from '../../utils/money'
import useQueryOperators from '../../hooks/useQueryOperators'
import { ERole } from '../../constants/role'
import useInfiniteQueryFinancesByPlayerId from '../../hooks/useInfiniteQueryFinancesByPlayerId'
import { ESortDirection, TFilter, TOrder } from '../../constants/queryParams'
import debounce from '../../utils/debounce'
import useQueryFinancesTotalByPlayerId from '../../hooks/useQueryFinancesTotalByPlayerId'
import mrtLocalization from '../../constants/mrtLocalization'
import { actionBalance, EActionBalance } from '../../constants/actionBalance'
import { typePaymentByOperatorOptions } from '../../constants/typePaymentByOperator'

type PlayerFinancialHistoryProps = Pick<IPlayer, 'id' | 'login' | 'currency'>

const recordFilterOptions = [
  {
    value: EActionBalance.REFILL,
    label: actionBalance[EActionBalance.REFILL],
  },
  {
    value: EActionBalance.WITHDRAWAL,
    label: actionBalance[EActionBalance.WITHDRAWAL],
  },
  ...typePaymentByOperatorOptions,
]

const PlayerFinancialHistoryTable: FC<PlayerFinancialHistoryProps> = observer((props) => {
  const { id: playerId, login, currency } = props
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null)
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'createdAt',
      desc: true,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [range, setRange] = useState<DateRange>({
    from: dateFallback(startOfMonth(new Date())),
    to: dateFallback(endOfDay(new Date())),
  })

  const { data: operators } = useQueryOperators()

  const query = {
    ...(range.from &&
      range.to && {
        from: format(range.from, EDateFormat.yyy_MM_dd_HH_mm),
        to: format(range.to, EDateFormat.yyy_MM_dd_HH_mm),
      }),
    filter: columnFilters.map((item) => {
      return [item.id, item.value]
    }) as TFilter[],
    order: sorting.map((item) => {
      const sortingType = item.desc ? ESortDirection.DESC : ESortDirection.ASC
      return [item.id, sortingType]
    }) as TOrder[],
    limit: 25,
    offset: 0,
  }

  const { from, to, filter } = query

  const { data: total, refetch: refetchTotal } = useQueryFinancesTotalByPlayerId(playerId, {
    from,
    to,
    filter,
  })

  const {
    data: finances,
    isLoading,
    isFetching,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch: refetchFinance,
  } = useInfiniteQueryFinancesByPlayerId(playerId, query, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  const financeRows = useMemo(() => {
    return finances?.pages.flatMap((page) => page.rows) || []
  }, [finances])

  const columns: MRT_ColumnDef<IPlayerFinance>[] = [
    {
      id: 'createdAt',
      accessorFn: (row) => new Date(row.createdAt),
      header: 'Время',
      sortingFn: 'datetime',
      sortDescFirst: true,
      enableColumnFilter: false,
      sortUndefined: 'first',
      Cell: ({ cell }) => {
        return formatToMoscowTZ(cell.getValue<Date>())
      },
    },
    {
      id: 'financeBefore',
      accessorFn: (row) => {
        return Number(row.financeBefore)
      },
      header: 'Баланс до',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        const value = cell.getValue<number>()
        return (
          <>
            {moneyFormat(String(value.toFixed(2)))}&nbsp;
            <Currency currency={currency} />
          </>
        )
      },
    },
    {
      id: 'amount',
      accessorFn: (row) => {
        return Number(row.amount)
      },
      header: 'Сумма',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        const value = cell.getValue<number>()
        return (
          <>
            {moneyFormat(String(value.toFixed(2)))}&nbsp;
            <Currency currency={currency} />
          </>
        )
      },
      Footer: () => {
        return (
          <Box component="span" color="warning.main">
            Всего: <br /> {moneyFormat(total?.totalAmount || '0')} <Currency currency={currency} />
          </Box>
        )
      },
    },
    {
      id: 'financeAfter',
      accessorFn: (row) => {
        return Number(row.financeAfter)
      },
      header: 'Баланс после',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        const value = cell.getValue<number>()
        return (
          <>
            {moneyFormat(String(value.toFixed(2)))}&nbsp;
            <Currency currency={currency} />
          </>
        )
      },
    },
    {
      id: 'operator',
      accessorFn: (item) => item.balanceTransaction?.operatorTransaction?.login || '',
      header: 'Оператор',
      enableSorting: false,
      filterVariant: 'multi-select',
      filterSelectOptions:
        operators?.rows
          .filter((item) => item.role === ERole.ADMINISTRATOR)
          .map((item) => {
            return {
              text: item.login,
              value: item.id,
            }
          }) || [],
    },
    {
      id: 'records',
      accessorFn: (item) => {
        if (Object.keys(item?.balanceTransaction || {}).length) return item.balanceTransaction?.description
        return item.gameSessionResult?.sessionId || 'Слот'
      },
      header: 'Записи',
      filterVariant: 'multi-select',
      filterSelectOptions: recordFilterOptions,
      // Filter: ({ column, header, table }) => {
      //   console.log(column, header, table)
      //   return <div>zxc</div>
      // },
      Cell: ({ cell, row }) => {
        const value = cell.getValue<number | string>()
        const gameId = row.original?.gameSessionResult?.gameId

        if (typeof value === 'string' || !gameId) {
          return value
        }

        return <Link to={`/game/${gameId}/session/${value}/detail`}>{value}</Link>
      },
    },
  ]

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0)
    } catch (error) {
      toast.error(`${error}`)
    }
  }, [sorting, columnFilters])

  const onScroll = useCallback(
    debounce(() => {
      if (!tableContainerRef.current) return

      const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current

      const isScrolledToEnd = scrollTop + clientHeight >= scrollHeight

      if (isScrolledToEnd && hasNextPage && !isFetching) fetchNextPage()
    }, 100),
    [hasNextPage]
  )

  const handleExportData = useCallback(async () => {
    try {
      const response = await ApiPlayers.exportXlsxFinanceByPlayerId({
        playerId,
        from,
        to,
      })

      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${login}.finances.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      toast.error(`Error downloading the file ${error}`)
    }
  }, [playerId, from, to])

  const refetchNew = (): void => {
    refetchFinance()
    refetchTotal()
  }

  const muiTable = useMaterialReactTable({
    ...mrtLocalization,
    columns,
    data: financeRows,
    enableRowVirtualization: true,
    rowVirtualizerOptions: {
      overscan: 3,
    },
    rowVirtualizerInstanceRef,
    muiTableContainerProps: {
      ref: tableContainerRef,
      onScroll,
    },
    enableFullScreenToggle: true,
    enablePagination: false,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    manualFiltering: true,
    manualSorting: true,
    enableSortingRemoval: false,
    enableDensityToggle: false,
    state: {
      sorting,
      columnFilters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      showColumnFilters: true,
      density: 'compact',
    },
    renderTopToolbarCustomActions: () => {
      return (
        <Stack gap={2} direction="row" alignItems="center" flexWrap="wrap">
          <DateRangePicker
            from={range.from}
            to={range.to}
            onChangeRange={(rangeDate) => {
              setRange({
                from: rangeDate?.from,
                to: rangeDate?.to,
              })
            }}
          />

          <Button size="small" variant="outlined" onClick={async () => refetchNew()}>
            Применить
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setColumnFilters([])
              refetchNew()
            }}>
            Сбросить фильтры
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setSorting([])
              refetchNew()
            }}>
            Сбросить сортировку
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={async () => {
              await handleExportData()
            }}
            startIcon={<FileDownloadIcon />}
            variant="contained">
            Экспорт в .xlsx
          </Button>
        </Stack>
      )
    },
  })

  return <MaterialReactTable table={muiTable} />
})

export default PlayerFinancialHistoryTable
