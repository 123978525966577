import { QueryOptions, useQuery, UseQueryResult } from 'react-query'
import { toast } from 'react-toastify'
import { ApiPlayers } from '../../api'
import { IQueryParams } from '../../constants/queryParams'

const useQueryFinancesTotalByPlayerId = (
  playerId: string,
  queryParams: Partial<Omit<IQueryParams, 'order'>>,
  options?: QueryOptions<{ totalAmount: string }, Error>
): UseQueryResult<{ totalAmount: string }, Error> => {
  return useQuery(
    [`finances total player`, playerId],
    async () => {
      return ApiPlayers.getFinanceTotalByPlayerId({
        playerId,
        ...queryParams,
      })
    },
    {
      enabled: !!playerId && !!queryParams.from && !!queryParams.to,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (err) => toast.error(`${err}`),
      ...options,
    }
  )
}

export default useQueryFinancesTotalByPlayerId
